import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withRouter } from "react-router-dom"
//import { NavLink} from "react-router-dom";
import axios from 'axios'
import { connectNode } from '../../../utils/global'
import { escapeHtml } from '../../../utils/textFunctions'
import '../../../styles/assets/css/pages/form5.css'
import { loadLocationWithMessage } from '../../../actions/location'
import { scid_yesno, mini_not_completed_reason,
  mini_scale1, mini_scale2, mini_q_a, mini_q_b, mini_q_c, mini_q_d , mini_q_e,
  mini_q_f, mini_q_f_v2, mini_q_g, mini_q_h, mini_q_i, mini_q_i_v2,
  mini_q_j1, mini_q_j1_v2, mini_q_j_meet_criteria, mini_q_j_meet_criteria_v2,
  mini_q_k, mini_q_l, mini_q_m, mini_q_n, mini_q_o, mini_q_q, mini_eligible,
  tobacco_scale, audit_c1_scale, audit_c2_scale, audit_c3_scale, audit_c_q,
  mini_borderline
} from '../variables/assessments/scid.js'

import PatientHead3 from "../patient/patient_head3"
import ReactHtmlParser from 'react-html-parser'
import ArrowRightIcon from '@material-ui/icons/ArrowRight'
import Button from '@material-ui/core/Button'
import WarningIcon from '@material-ui/icons/Warning'

class ScidResult extends Component {
  constructor() {
      super()
      //var [fields, setFields] = useState([{ value: null }])
      this.state = {
          status: false,
          message: '',
          dataItems: [],
      }
  }

  componentDidMount() {
    this.getData()
    window.scrollTo(0, 0)
  }

  getData = () => {
    axios({
      method: 'post',
      url: connectNode + 'admin/patient_assessment/view_mini_result',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      data: {
        pid: this.props.match.params.pid,
      }
    })
    .then((result) => {
      if(result['data'] != 0) {
        this.setState({
          dataItems: result['data'][0]
        })
      }
    })
  }

  findLabelByValue(arr, searchValue) {
    var label = ''
    var result = arr.find(obj => { return obj.value === searchValue })
    if(typeof result !== "undefined") {
      label = result.label
    }
    return label
  }

  render() {
    return (
      <div className="admin_patient_detail box_border4 mini_result">
        <div className="row">
          <div className="small-12 columns headerPage1">MINI Result</div>
        </div>
        <div className="row">
          <div className="small-12 columns ">
            <div className="box_border5">
              <PatientHead3 />
              <div className="row">
                <div className="small-12 columns box_inner2 top_space1">
                  <div className="small-12 columns">

                  <div className="small-12 columns box5 top_space1">
                    <div>
                      <div className="small-12 columns">
                        <div className="row no_margin_bottom">
                          <div className="small-10 columns">A70. Has there been one or more Manic Episodes?</div>
                          <div className="small-2 columns">Yes</div>
                        </div>
                        <div className="row no_margin_bottom">
                          <div className="small-1 columns">&nbsp;</div>
                          <div className="small-9 columns">Currently Manic</div>
                          <div className="small-2 columns">Yes</div>
                        </div>
                        <div className="row no_margin_bottom">
                          <div className="small-1 columns">&nbsp;</div>
                          <div className="small-9 columns">Currently Depressed</div>
                          <div className="small-2 columns">Yes</div>
                        </div>
                        <div className="small-12 columns sep_line1">&nbsp;</div>
                      </div>
                      <div className="small-12 columns">
                        <div className="row no_margin_bottom">
                          <div className="small-10 columns">A71. Has there been at least one Hypomanic Episode and at least one Major Depressive Episode?</div>
                          <div className="small-2 columns">Yes</div>
                        </div>
                        <div className="row no_margin_bottom">
                          <div className="small-1 columns">&nbsp;</div>
                          <div className="small-9 columns">Currently Hypomanic</div>
                          <div className="small-2 columns">Yes</div>
                        </div>
                        <div className="row no_margin_bottom">
                          <div className="small-1 columns">&nbsp;</div>
                          <div className="small-9 columns">Currently Depressed</div>
                          <div className="small-2 columns">Yes</div>
                        </div>
                        <div className="small-12 columns sep_line1">&nbsp;</div>
                        <div className="row no_margin_bottom">
                          <div className="small-10 columns">A72. Have symptoms characteristic of a bipolar and related disorder (e.g., elevated or irritable mood) that cause clinically significant distress or impairment in social, occupational, or other important areas of functioning predominated the clinical presentation?</div>
                          <div className="small-2 columns">Yes</div>
                        </div>
                        <div className="small-12 columns sep_line1">&nbsp;</div>
                        <div className="row no_margin_bottom">
                          <div className="small-10 columns">A73. Has there been at least one Major Depressive Episode?</div>
                          <div className="small-2 columns">Yes</div>
                        </div>
                        <div className="row no_margin_bottom">
                          <div className="small-1 columns">&nbsp;</div>
                          <div className="small-9 columns">Currently in an episode</div>
                          <div className="small-2 columns">Yes</div>
                        </div>
                        <div className="small-12 columns sep_line1">&nbsp;</div>
                        <div className="row no_margin_bottom">
                          <div className="small-10 columns">A74. Have symptoms characteristic of a depressive disorder (e.g. depredded mood or loss of interest) that cause clinically significant distress or impairment in social, occupational, or other important areas of functioning predominated the clinical presentation?</div>
                          <div className="small-2 columns">Yes</div>
                        </div>
                        <div className="small-12 columns sep_line1">&nbsp;</div>
                      </div>
                      <div>
                        <div className="small-12 columns">
                          <div className="row no_margin_bottom text_align_right">
                            <a href={"/admin/assessments/edit_scid1/"+this.props.match.params.pid+"/edit"} className="edit_button1" >
                                Edit
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>



                  </div>

                  <div className="small-12 columns box5 top_space1">
                    <div>
                      <div className="small-12 columns">
                        <div className="row no_margin_bottom">
                          <div className="small-10 columns">B13. Possible Lifetime Psychotic Disorder?</div>
                          <div className="small-2 columns">Yes</div>
                        </div>
                        <div className="small-12 columns sep_line1">&nbsp;</div>
                        <div className="row no_margin_bottom">
                          <div className="small-10 columns">B14. Possible Current Psychotic Disorder?</div>
                          <div className="small-2 columns">Yes</div>
                        </div>
                        <div className="small-12 columns sep_line1">&nbsp;</div>
                      </div>
                      <div>
                        <div className="small-12 columns">
                          <div className="row no_margin_bottom text_align_right">
                            <a href={"/admin/assessments/edit_scid1/"+this.props.match.params.pid+"/edit"} className="edit_button1" >
                                Edit
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="small-12 columns box5 top_space1">
                    <div>
                      <div className="small-12 columns">
                        <div className="row no_margin_bottom">
                          <div className="small-10 columns">C13. Alcohol Use Disorder (past year)</div>
                          <div className="small-2 columns">Yes</div>
                        </div>
                        <div className="row no_margin_bottom">
                          <div className="small-1 columns">&nbsp;</div>
                          <div className="small-9 columns">Mild Alcohol Use Disorder</div>
                          <div className="small-2 columns">Yes</div>
                        </div>
                        <div className="row no_margin_bottom">
                          <div className="small-1 columns">&nbsp;</div>
                          <div className="small-9 columns">Moderate Alcohol Use Disorder</div>
                          <div className="small-2 columns">Yes</div>
                        </div>
                        <div className="row no_margin_bottom">
                          <div className="small-1 columns">&nbsp;</div>
                          <div className="small-9 columns">Severe Alcohol Use Disorder</div>
                          <div className="small-2 columns">Yes</div>
                        </div>
                        <div className="small-12 columns sep_line1">&nbsp;</div>
                      </div>
                      <div className="small-12 columns">
                        <div className="row no_margin_bottom">
                          <div className="small-10 columns">C35. Nonalcohol Substance Use Disorder (past year)</div>
                          <div className="small-2 columns">Yes</div>
                        </div>
                        <div className="row no_margin_bottom">
                          <div className="small-1 columns">&nbsp;</div>
                          <div className="small-9 columns">Mild Nonalcohol Substance Use Disorder</div>
                          <div className="small-2 columns">Yes</div>
                        </div>
                        <div className="row no_margin_bottom">
                          <div className="small-1 columns">&nbsp;</div>
                          <div className="small-9 columns">Moderate Nonalcohol Substance Use Disorder</div>
                          <div className="small-2 columns">Yes</div>
                        </div>
                        <div className="row no_margin_bottom">
                          <div className="small-1 columns">&nbsp;</div>
                          <div className="small-9 columns">Severe Nonalcohol Substance Use Disorder</div>
                          <div className="small-2 columns">Yes</div>
                        </div>
                        <div className="small-12 columns sep_line1">&nbsp;</div>
                      </div>
                      <div>
                        <div className="small-12 columns">
                          <div className="row no_margin_bottom text_align_right">
                            <a href={"/admin/assessments/edit_scid1/"+this.props.match.params.pid+"/edit"} className="edit_button1" >
                                Edit
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="small-12 columns box5 top_space1">
                    <div>
                      <div className="small-12 columns">
                        <div className="row no_margin_bottom">
                          <div className="small-10 columns">D16. Panic Disorder (current)</div>
                          <div className="small-2 columns">Yes</div>
                        </div>
                        <div className="small-12 columns sep_line1">&nbsp;</div>
                        <div className="row no_margin_bottom">
                          <div className="small-10 columns">D22. Agoraphobia (current)</div>
                          <div className="small-2 columns">Yes</div>
                        </div>
                        <div className="small-12 columns sep_line1">&nbsp;</div>
                        <div className="row no_margin_bottom">
                          <div className="small-10 columns">D28. Social Anxiety Disorder (current)</div>
                          <div className="small-2 columns">Yes</div>
                        </div>
                        <div className="small-12 columns sep_line1">&nbsp;</div>
                        <div className="row no_margin_bottom">
                          <div className="small-10 columns">D37. Generalized Anxiety Disorder (current)</div>
                          <div className="small-2 columns">Yes</div>
                        </div>
                        <div className="small-12 columns sep_line1">&nbsp;</div>
                      </div>
                      <div>
                        <div className="small-12 columns">
                          <div className="row no_margin_bottom text_align_right">
                            <a href={"/admin/assessments/edit_scid1/"+this.props.match.params.pid+"/edit"} className="edit_button1" >
                                Edit
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="small-12 columns box5 top_space1">
                    <div>
                      <div className="small-12 columns">
                        <div className="row no_margin_bottom">
                          <div className="small-10 columns">E5. Obsessive-Compulsive Disorder (current)</div>
                          <div className="small-2 columns">Yes</div>
                        </div>
                        <div className="small-12 columns sep_line1">&nbsp;</div>
                        <div className="row no_margin_bottom">
                          <div className="small-10 columns">F24. Attention-Deficit/Hyperactivity Disorder (current)</div>
                          <div className="small-2 columns">Yes</div>
                        </div>
                        <div className="small-12 columns sep_line1">&nbsp;</div>
                        <div className="row no_margin_bottom">
                          <div className="small-10 columns">G18. Posttraumatic Stress Disorder (current)</div>
                          <div className="small-2 columns">Yes</div>
                        </div>
                        <div className="small-12 columns sep_line1">&nbsp;</div>
                        <div className="row no_margin_bottom">
                          <div className="small-10 columns">H3. Anorexia Nervosa (current)</div>
                          <div className="small-2 columns">Yes</div>
                        </div>
                        <div className="small-12 columns sep_line1">&nbsp;</div>
                        <div className="row no_margin_bottom">
                          <div className="small-10 columns">H7. Bulimia Nervosa (current)</div>
                          <div className="small-2 columns">Yes</div>
                        </div>
                        <div className="small-12 columns sep_line1">&nbsp;</div>
                        <div className="row no_margin_bottom">
                          <div className="small-10 columns">H14. Binge-Eating Disorder (current)</div>
                          <div className="small-2 columns">Yes</div>
                        </div>
                        <div className="small-12 columns sep_line1">&nbsp;</div>
                      </div>
                      <div>
                        <div className="small-12 columns">
                          <div className="row no_margin_bottom text_align_right">
                            <a href={"/admin/assessments/edit_scid1/"+this.props.match.params.pid+"/edit"} className="edit_button1" >
                                Edit
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="small-12 columns box5 top_space1">
                    <div>
                      <div className="small-12 columns">
                        <div className="row no_margin_bottom">
                          <div className="small-10 columns">I1. IF PATIENT IS ABIOLOGICAL MALE OR AFEMALE WHOIS NOT MENSTRUATING, SKIP TO THE NEXT QUESTION (12).</div>
                          <div className="small-2 columns">Yes</div>
                        </div>
                        <div className="small-12 columns sep_line1">&nbsp;</div>
                        <div className="row no_margin_bottom">
                          <div className="small-10 columns">I2. During the past month, have there been things that have made you especially anxious or afraid, like flying, seeing blood, getting a shot, heights, closed places, or certain kinds of animals or insects?</div>
                          <div className="small-2 columns">Yes</div>
                        </div>
                        <div className="small-12 columns sep_line1">&nbsp;</div>
                        <div className="row no_margin_bottom">
                          <div className="small-10 columns">I3. During the past month, have you been especially anxious about being separated from people you're attached to (like your parents, children, or partner)?</div>
                          <div className="small-2 columns">Yes</div>
                        </div>
                        <div className="small-12 columns sep_line1">&nbsp;</div>
                        <div className="row no_margin_bottom">
                          <div className="small-10 columns">I4. During the past month, have you had a very difficult time throwing out, selling, or giving away things?</div>
                          <div className="small-2 columns">Yes</div>
                        </div>
                        <div className="small-12 columns sep_line1">&nbsp;</div>
                        <div className="row no_margin_bottom">
                          <div className="small-10 columns">I5. During the past month, have you been very concerned that there is something wrong with your physical appearance or the way one or more parts of your body look?</div>
                          <div className="small-2 columns">Yes</div>
                        </div>
                        <div className="small-12 columns sep_line1">&nbsp;</div>
                        <div className="row no_margin_bottom">
                          <div className="small-10 columns">I6. During the past month, have you been repeatedlv pulling out hair from anywhere on your body, orner 16 than for cosmetic reasons?</div>
                          <div className="small-2 columns">Yes</div>
                        </div>
                        <div className="small-12 columns sep_line1">&nbsp;</div>
                        <div className="row no_margin_bottom">
                          <div className="small-10 columns">I7. In the past month, have you been repeatedly picking at vour skin with your fingernails, tweezers, pins, or other objects?</div>
                          <div className="small-2 columns">Yes</div>
                        </div>
                        <div className="small-12 columns sep_line1">&nbsp;</div>
                        <div className="row no_margin_bottom">
                          <div className="small-10 columns">I8. Over the past month, have you been concerned that you are not getting enough good sleep or dont feel rested when you wake up?</div>
                          <div className="small-2 columns">Yes</div>
                        </div>
                        <div className="small-12 columns sep_line1">&nbsp;</div>
                        <div className="row no_margin_bottom">
                          <div className="small-10 columns">I9. Over the past month, have you often had days when you felt sleepy, despite having slept for at least 7 hours?</div>
                          <div className="small-2 columns">Yes</div>
                        </div>
                        <div className="small-12 columns sep_line1">&nbsp;</div>
                        <div className="row no_margin_bottom">
                          <div className="small-10 columns">I10. During the past month, since (1 MONTH AGO), have you been uninterested in food in general or have I10 youkept forgetting to eat?</div>
                          <div className="small-2 columns">Yes</div>
                        </div>
                        <div className="small-12 columns sep_line1">&nbsp;</div>
                        <div className="row no_margin_bottom">
                          <div className="small-10 columns">I11. During the past month, have you been very bothered by physical symptoms?</div>
                          <div className="small-2 columns">Yes</div>
                        </div>
                        <div className="small-12 columns sep_line1">&nbsp;</div>
                        <div className="row no_margin_bottom">
                          <div className="small-10 columns">I12. During the past month, have you spent a lot disease?</div>
                          <div className="small-2 columns">Yes</div>
                        </div>
                        <div className="small-12 columns sep_line1">&nbsp;</div>
                        <div className="row no_margin_bottom">
                          <div className="small-10 columns">I13. During the past year, have you had periods in which you frequently lost control of your temper and ended up yelling or getting into arguments with others?</div>
                          <div className="small-2 columns">Yes</div>
                        </div>
                        <div className="small-12 columns sep_line1">&nbsp;</div>
                        <div className="row no_margin_bottom">
                          <div className="small-10 columns">I14. During the past year, have you regularly gambled or bought more lottery tickets than you could afford to?</div>
                          <div className="small-2 columns">Yes</div>
                        </div>
                        <div className="small-12 columns sep_line1">&nbsp;</div>
                      </div>
                      <div>
                        <div className="small-12 columns">
                          <div className="row no_margin_bottom text_align_right">
                            <a href={"/admin/assessments/edit_scid1/"+this.props.match.params.pid+"/edit"} className="edit_button1" >
                                Edit
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="small-12 columns box5 top_space1">
                    <div>
                      <div className="small-12 columns">
                        <div className="row no_margin_bottom">
                          <div className="small-10 columns">J1. Probable Mental Disorder due to Another Medical Condition</div>
                          <div className="small-2 columns">Yes</div>
                        </div>
                        <div className="row no_margin_bottom">
                          <div className="small-1 columns">&nbsp;</div>
                          <div className="small-11 columns">Medical condition: .........</div>
                        </div>
                        <div className="row no_margin_bottom">
                          <div className="small-1 columns">&nbsp;</div>
                          <div className="small-9 columns">Depressive Disorder</div>
                          <div className="small-2 columns">Yes</div>
                        </div>
                        <div className="row no_margin_bottom">
                          <div className="small-1 columns">&nbsp;</div>
                          <div className="small-9 columns">Bipolar and Related Disorder</div>
                          <div className="small-2 columns">Yes</div>
                        </div>
                        <div className="row no_margin_bottom">
                          <div className="small-1 columns">&nbsp;</div>
                          <div className="small-9 columns">Anxiety Disorder</div>
                          <div className="small-2 columns">Yes</div>
                        </div>
                        <div className="row no_margin_bottom">
                          <div className="small-1 columns">&nbsp;</div>
                          <div className="small-9 columns">Obsessive-Compulsive and Related Disorder</div>
                          <div className="small-2 columns">Yes</div>
                        </div>

                        <div className="small-12 columns sep_line1">&nbsp;</div>
                      </div>
                      <div className="small-12 columns">
                        <div className="row no_margin_bottom">
                          <div className="small-10 columns">J2. Probable Substance/Medication-Induced Mental Disorder</div>
                          <div className="small-2 columns">Yes</div>
                        </div>
                        <div className="row no_margin_bottom">
                          <div className="small-1 columns">&nbsp;</div>
                          <div className="small-11 columns">Medical condition: .........</div>
                        </div>
                        <div className="row no_margin_bottom">
                          <div className="small-1 columns">&nbsp;</div>
                          <div className="small-9 columns">Depressive Disorder</div>
                          <div className="small-2 columns">Yes</div>
                        </div>
                        <div className="row no_margin_bottom">
                          <div className="small-1 columns">&nbsp;</div>
                          <div className="small-9 columns">Bipolar and Related Disorder</div>
                          <div className="small-2 columns">Yes</div>
                        </div>
                        <div className="row no_margin_bottom">
                          <div className="small-1 columns">&nbsp;</div>
                          <div className="small-9 columns">Anxiety Disorder</div>
                          <div className="small-2 columns">Yes</div>
                        </div>
                        <div className="row no_margin_bottom">
                          <div className="small-1 columns">&nbsp;</div>
                          <div className="small-9 columns">Obsessive-Compulsive and Related Disorder</div>
                          <div className="small-2 columns">Yes</div>
                        </div>
                        <div className="small-12 columns sep_line1">&nbsp;</div>
                      </div>
                      <div>
                        <div className="small-12 columns">
                          <div className="row no_margin_bottom text_align_right">
                            <a href={"/admin/assessments/edit_scid1/"+this.props.match.params.pid+"/edit"} className="edit_button1" >
                                Edit
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>



                  </div>

                  <div className="small-12 columns box5 top_space1">
                    <div>
                      <div className="small-12 columns">
                        <div className="row no_margin_bottom">
                          <div className="small-12 columns">Result: Eligible</div>
                        </div>
                        <div className="row no_margin_bottom">
                          <div className="small-12 columns">Note:
                          <br />.........<br />.........<br />.........<br />.........</div>
                        </div>
                        <div className="small-12 columns sep_line1">&nbsp;</div>
                      </div>
                      <div>
                        <div className="small-12 columns">
                          <div className="row no_margin_bottom text_align_right">
                            <a href={"/admin/assessments/edit_scid1/"+this.props.match.params.pid+"/edit"} className="edit_button1" >
                                Edit
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    )
  }
}

/*<div className="small-12 columns box5 top_space1">
<div>{mini_q_c.map(each =>
  <div className="small-12 columns">
    <div className="row no_margin_bottom">
      <div className="small-9 columns">C{each.value}. {each.label}</div>
      <div className="small-3 columns">
      {this.findLabelByValue(mini_scale1, this.state.dataItems['c'+each.value])}
      </div>
    </div>
    <div className="small-12 columns sep_line1">&nbsp;</div>
  </div>)}
</div>
<div>
  <div className="small-12 columns">
    <div className="row no_margin_bottom text_align_right">
      <a href={"/admin/assessments/edit_mini2/"+this.props.match.params.pid+"/edit"} className="edit_button1" >
          Edit
      </a>
    </div>
  </div>
</div>
</div>*/

const mapStateToProps = (state) => {
  //console.log('patientstate STATE: ', state)
  return state
}

const mapDispatchToProps = (dispatch) => ({
  onLoadPages(response) {
    //console.log('AddPatient mapDispatchToProps')
    dispatch({
      type: 'LOAD_USER_AUTH',
      payload: response,
    })
  },
  onLoadLocation(route, msg) {
    dispatch(loadLocationWithMessage(route, msg))
  },
})

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(ScidResult)
