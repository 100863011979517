import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withRouter } from "react-router-dom"
//import { NavLink} from "react-router-dom";
import axios from 'axios'
import { connectNode } from '../../../utils/global'
import { escapeHtml } from '../../../utils/textFunctions'
import '../../../styles/assets/css/pages/form5.css'
import { loadLocationWithMessage } from '../../../actions/location'
import { mini_eligible } from '../variables/assessments/scid.js'

import WarningIcon from '@material-ui/icons/Warning'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import ArrowRightIcon from '@material-ui/icons/ArrowRight'
import validator from 'validator'
import StepperSCID from './stepper_SCID'

//import ReactQuill from "react-quill"
import 'react-quill/dist/quill.snow.css'

class Scid6 extends Component {
  constructor() {
      super()
      this.quill = require('react-quill')
      //var [fields, setFields] = useState([{ value: null }])
      this.state = {
          status: false,
          status_submit: true,
          edit_status: false,
          message: '',
          appt_id: 0,
          start_time: Math.floor(new Date().getTime()/1000),
          next_step: 'complete',
          bd_result: 0,
          note: '',
      }
  }

  componentDidMount() {
    window.scrollTo(0, 0)
    //this.getAssmProcess()
    //console.log(this.state.start_datetime)
  }

  getAssmProcess = () => {
    if(this.props.match.params.task == 'edit') {
      this.getMiniData('edit')
    } else {
    axios({
      method: 'post',
      url: connectNode + 'admin/assessment/chk_mini_status',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      data: {
        uid: this.props.authReducer.uid,
        pid: this.props.match.params.pid,
        timepoint: this.props.match.params.timepoint,
      }
    })
    .then((result) => {
        console.log(result['data'])
        if(result['data'] === 0) {
          this.setState({
            message: 'Something went wrong.',
          })
        } else {
          //console.log('step: '+result['data'].mini_step)
          if(result['data'].mini_step !== 'mini9') {
            this.props.history.push('/admin/assessments/'+result['data'].mini_step+'/'+this.props.match.params.pid+'/'+this.props.match.params.timepoint)
          } else {
            this.setState({
              appt_id: result['data'].id,
            })
          }
        }
      }
    )}
  }

  getMiniData = (edit_status) => {
    var get_edit_status = false
    if(edit_status == 'edit') {
      get_edit_status = true
    }
    axios({
      method: 'post',
      url: connectNode + 'admin/assessment/get_mini_data',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      data: {
        pid: this.props.match.params.pid,
      }
    })
    .then((result) => {
      this.setState({
        completed_status: true,
        appt_id: 1,
        edit_status: get_edit_status,
        bd_result: result['data'].bd_result == null ? 0 : result['data'].bd_result,
        note: result['data'].note == null ? '' : result['data'].note,
      })
    })
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    })
  }

  handleChange2 = (contents) => {
    this.setState({
      note: contents,
    })
  }

  isValid = (str) => {
   return !/[\'\\"]/g.test(str)
  }

  handleSubmit = (e) => {
    e.preventDefault()
    var pid = this.props.match.params.pid
    var timepoint = this.props.match.params.timepoint
    if(this.state.bd_result === 0) {
      this.setState({
        message: 'Please select and fill all answers ',
      })
      window.scrollTo(0, 0)
    } else {
      this.setState({
        status_submit: false,
        message: '',
      })
      axios({
        method: 'post',
        url: connectNode + 'admin/assessment/update_mini10',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json;charset=UTF-8'
        },
        data: {
          uid: this.props.authReducer.uid,
          user: this.state,
          pid: pid,
          timepoint: timepoint,
        }
      })
      .then((result) => {
        if(result['data'] === this.state.next_step) {
          this.props.onLoadLocation('/admin/assessments/'+this.state.next_step+'/'+pid+'/'+timepoint)
          this.props.history.push('/admin/assessments/'+this.state.next_step+'/'+pid+'/'+timepoint)
        }
      })
    }
  }

  handleEdit = (e) => {
    e.preventDefault()
    var pid = this.props.match.params.pid
    axios({
      method: 'post',
      url: connectNode + 'admin/assessment/edit_mini7',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      data: {
        uid: this.props.authReducer.uid,
        user: this.state,
        pid: pid,
        task: 'edit',
      }
    })
    .then((result) => {
      console.log(result['data'])
        if(result['data'] == pid) {
          this.props.onLoadLocation('/admin/assessments/mini_result/'+pid+'/-1', 'Edit successfully!')
          this.props.history.push('/admin/assessments/mini_result/'+pid+'/-1')
        }
      }
    )
  }

  render() {
    const ReactQuill = this.quill
    return (
      <div className="assm_mini mini_result box_border4">
        { //this.state.appt_id > 0 &&
        <div className="row">
          <div className="small-12 columns headerPage1">
            SCID Summary Sheet: Intake Appointment
          </div>
          {//this.props.match.params.task == 'edit' && //!this.props.match.params.task == 'edit' &&
          <StepperSCID activeStep={5} type={this.props.match.params.type} timepoint={this.props.match.params.timepoint} />}
        </div>}
        { this.state.message !== '' &&
        <div className="row warning_msg6">
          <div className="small-12 columns ">
            <div className="float_left1"><WarningIcon /></div>
            <div className="txt_warning_msg1">{ this.state.message }</div>
          </div>
        </div>
        }
        { //this.state.appt_id > 0 &&
          <form onSubmit={this.handleSubmit}>
          <div className="row">
            <div className="small-12 columns box_mini_result">
              <div className="row">
                <div className="small-12 medium-1 columns bold">
                  Result:
                </div>
                {mini_eligible.map(each =>
                <div className="small-12 medium-2 columns float_left_mini" key={'sn'+each.label}>
                  <input type="radio" name="bd_result" value={each.value}
                  checked={this.state.bd_result == each.value && 'checked'}
                  onChange={(e) => this.handleChange(e)}/>
                  <span>{each.label}</span>
                </div>)}
              </div>
              <div className="row top_space1">
                <div className="small-12 medium-1 columns bold">
                  Note:
                </div>
                <div className="small-12 medium-11 columns ">
                <ReactQuill name="note" modules={{toolbar: false}}
                  theme='snow' value={this.state.note}
                  onChange={this.handleChange2}
                  style={{height: '200px'}} />
                </div>
              </div>
            </div>
            <div className="small-12 columns align_right">
            {this.state.status_submit && !this.state.edit_status &&
              <Button variant="contained" color="primary" disableElevation
                 onClick={this.handleSubmit} type="submit" className="submit_1">
                  Submit
              </Button>}
            {!this.state.status_submit && !this.state.edit_status &&
              <Button variant="contained" disabled
                 onClick={this.handleSubmit} type="submit" className="submit_1">
                  Submit
              </Button>}
              {this.state.edit_status &&
              <Button variant="contained" color="primary" disableElevation
                 onClick={(e) => this.handleEdit(e)} className="submit_1">
                  Submit
              </Button>}
            </div>
          </div>


        </form>}


    </div>
  )}
}

const mapStateToProps = (state) => {
  return state
}

const mapDispatchToProps = (dispatch) => ({
  onLoadPages(response) {
    dispatch({
      type: 'LOAD_USER_AUTH',
      payload: response,
    })
  },
  onLoadLocation(route, msg) {
    dispatch(loadLocationWithMessage(route, msg))
  },
})

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(Scid6)
