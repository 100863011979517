import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withRouter } from "react-router-dom"
//import { NavLink} from "react-router-dom";
import axios from 'axios'
import { connectNode } from '../../../utils/global'
import { escapeHtml } from '../../../utils/textFunctions'
import '../../../styles/assets/css/pages/form5.css'
import { loadLocationWithMessage } from '../../../actions/location'
import { scid_yesno, scid_ynmb, mini_not_completed_reason,
  mini_q_a, mini_q_b, mini_scale1, mini_scale2 } from '../variables/assessments/scid.js'

import WarningIcon from '@material-ui/icons/Warning'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import ArrowRightIcon from '@material-ui/icons/ArrowRight'

import StepperSCID from './stepper_SCID'

class Scid5 extends Component {
  constructor() {
      super()
      //var [fields, setFields] = useState([{ value: null }])
      this.state = {
          status: false,
          completed_status: false,
          edit_status: false,
          message: '',
          appt_id: 0,
          start_time: Math.floor(new Date().getTime()/1000),
          //start_datetime: new Date().toLocaleString('en-US', { hour12: false }).slice(0, 19).replace('T', ' '),
          next_step: 'scid6',
          completed_visit: 0,
          not_completed_reason: 0,
          not_completed_reason_other: '',
          date_visit: new Date().toISOString().substring(0, 10),
          rater: 0,
          rater_name: '',
          a1: 0,
          a2: 0,
          a3: 0,
          a4: 0,
          a5: 0,
          a6: 0,
          b1: 0,
          b2: 0,
      }
  }

  componentDidMount() {
    window.scrollTo(0, 0)
    this.getAssmProcess()
    //console.log(this.state.start_datetime)
  }

  getAssmProcess = () => {
    // get Rater name //
    axios({
      method: 'post',
      url: connectNode + 'patient/general/get_clinician_name',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      data: {
        cid: this.props.authReducer.uid,
      }
    })
    .then((result) => {
      this.setState({
        rater: this.props.authReducer.uid,
        rater_name: result['data'].fullname,
      })
    })

    /*if(this.props.match.params.task == 'edit') {
      this.getMiniData('edit')
    } else {
      // Initial MINI form to database //
      axios({
        method: 'post',
        url: connectNode + 'admin/assessment/chk_mini_status',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json;charset=UTF-8'
        },
        data: {
          uid: this.props.authReducer.uid,
          pid: this.props.match.params.pid,
          timepoint: this.props.match.params.timepoint,
        }
      })
      .then((result) => {
          //console.log(result['data'])
          if(result['data'] === 0) {
            this.setState({
              message: 'Something went wrong.',
            })
          } else {
            //console.log('step: '+result['data'].mini_step)
            if(result['data'].mini_step === 'complete') {
              this.props.history.push('/admin/assessments/mini_result/'+this.props.match.params.pid+'/'+this.props.match.params.timepoint)
            } else if(result['data'].mini_step !== 'mini1') {
              //this.props.history.push('/admin/assessments/'+result['data'].mini_step+'/'+this.props.match.params.pid+'/'+this.props.match.params.timepoint)
              axios({
                method: 'post',
                url: connectNode + 'admin/assessment/get_mini_data',
                headers: {
                  'Accept': 'application/json',
                  'Content-Type': 'application/json;charset=UTF-8'
                },
                data: {
                  pid: this.props.match.params.pid,
                }
              })
              .then((result) => {
                this.setState({
                  completed_status: true,
                  appt_id: this.props.match.params.appt_id,
                  completed_visit: result['data'].completed_visit,
                  not_completed_reason: result['data'].not_completed_reason,
                  not_completed_reason_other: result['data'].not_completed_reason_other,
                  date_visit: result['data'].date_visit,
                  a1: result['data'].a1,
                  a2: result['data'].a2,
                  a3: result['data'].a3,
                  a4: result['data'].a4,
                  a5: result['data'].a5,
                  a6: result['data'].a6,
                  b1: result['data'].b1,
                  b2: result['data'].b2,
                })
              })
            } else {
              this.setState({
                appt_id: result['data'].id,
                //rater: this.props.authReducer.uid,
                //rater_name: result['data'].fullname,
              })
            }
          }
        }
      )
    }*/

  }

  /*getMiniData = (edit_status) => {
    var get_edit_status = false
    if(edit_status == 'edit') {
      get_edit_status = true
    }
    axios({
      method: 'post',
      url: connectNode + 'admin/assessment/get_mini_data',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      data: {
        pid: this.props.match.params.pid,
      }
    })
    .then((result) => {
      this.setState({
        completed_status: true,
        edit_status: get_edit_status,
        appt_id: this.props.match.params.appt_id,
        completed_visit: result['data'].completed_visit,
        not_completed_reason: result['data'].not_completed_reason,
        not_completed_reason_other: result['data'].not_completed_reason_other,
        date_visit: result['data'].date_visit,
        rater_name: result['data'].fullname,
        a1: result['data'].a1 == null ? 0 : result['data'].a1,
        a2: result['data'].a2 == null ? 0 : result['data'].a2,
        a3: result['data'].a3 == null ? 0 : result['data'].a3,
        a4: result['data'].a4 == null ? 0 : result['data'].a4,
        a5: result['data'].a5 == null ? 0 : result['data'].a5,
        a6: result['data'].a6 == null ? 0 : result['data'].a6,
        b1: result['data'].b1 == null ? 0 : result['data'].b1,
        b2: result['data'].b2 == null ? 0 : result['data'].b2,
      })
    })
  }*/

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    })
  }

  handleNext = (page) => {
    window.location.href = "/admin/assessments/"+page+"/"+this.props.match.params.pid+"/-1"
  }

  handleSubmit = (e) => {
    e.preventDefault()
    var pid = this.props.match.params.pid
    var timepoint = this.props.match.params.timepoint
    this.props.onLoadLocation('/admin/assessments/'+this.state.next_step+'/'+pid+'/'+timepoint)
    this.props.history.push('/admin/assessments/'+this.state.next_step+'/'+pid+'/'+timepoint)
    /*if(this.state.date_visit === '' || this.state.completed_visit === 0) {
      this.setState({
        message: 'Please select all answers ',
      })
      window.scrollTo(0, 0)
    } else {
      /*if(this.state.completed_visit === '2' && this.state.not_completed_reason === 0) {
        this.setState({
          message: 'Please select all answers ',
        })
        window.scrollTo(0, 0)
      } else {
        axios({
          method: 'post',
          url: connectNode + 'admin/assessment/update_mini1',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json;charset=UTF-8'
          },
          data: {
            uid: this.props.authReducer.uid,
            user: this.state,
            pid: pid,
            timepoint: timepoint,
          }
        })
        .then((result) => {
            if(result['data'] === this.state.next_step) {
              this.props.onLoadLocation('/admin/assessments/'+this.state.next_step+'/'+pid+'/'+timepoint)
              this.props.history.push('/admin/assessments/'+this.state.next_step+'/'+pid+'/'+timepoint)
            }
          }
        )
      }*/
    //}
  }

  /*handleEdit = (e) => {
    e.preventDefault()
    var pid = this.props.match.params.pid
    axios({
      method: 'post',
      url: connectNode + 'admin/assessment/edit_mini1',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      data: {
        uid: this.props.authReducer.uid,
        user: this.state,
        pid: pid,
        task: 'edit',
      }
    })
    .then((result) => {
        if(result['data'] == pid) {
          this.props.onLoadLocation('/admin/assessments/mini_result/'+pid+'/-1', 'Edit successfully!')
          this.props.history.push('/admin/assessments/mini_result/'+pid+'/-1')
        }
      }
    )
  }*/

  render() {
    return (
      <div className="assm_mini box_border4">
        { //this.state.appt_id > 0 &&
        <div className="row">
          <div className="small-12 columns headerPage1">
            QuickSCID5: Intake Appointment
          </div>
          <StepperSCID activeStep={4} type={this.props.match.params.type} timepoint={this.props.match.params.timepoint} />
        </div>}
        {/*this.props.match.params.task == 'edit' &&
        <div className="row">
          <div className="small-12 columns headerPage1">
            MINI Summary Sheet: Intake Appointment
          </div>
        </div>*/}
        { this.state.message !== '' &&
        <div className="row warning_msg6">
          <div className="small-12 columns ">
            <div className="float_left1"><WarningIcon /></div>
            <div className="txt_warning_msg1">{ this.state.message }</div>
          </div>
        </div>
        }
        { //(this.state.appt_id > 0 || this.props.match.params.task == 'edit') &&
          <form onSubmit={this.handleSubmit}>
          <div className="row">
          <div className="small-12 columns box1">
            <div className="row show-for-medium bold">
              <div className="medium-9 columns">&nbsp;</div>
              {scid_ynmb.map(each =>
                each.value > 0 &&
                <div className="medium-1 columns headerResize1" key={'sch'+each.label}>
                  {each.label}
                </div>
              )}
              <div className="small-12 columns">
                <div className="sep_line1">&nbsp;</div>
              </div>
            </div>
            <div className="row" >
              <div className="small-12 medium-9 columns">
                J1. Probable Mental Disorder due to Another Medical Condition
              </div>
              {scid_ynmb.map(each =>
                each.value > 0 &&
                <div className="small-12 medium-1 columns align_center" key={'sc'+each.label}>
                  <input type="radio" name="a70" value={each.value}
                  checked={this.state['a70'] == each.value && 'checked'}
                  disabled={(this.state.completed_status && !this.state.edit_status) && 'disabled'}
                  onChange={(e) => this.handleChange(e)}/>
                  <span className="show-for-small-only">{each.label}</span>
                </div>)}
            </div>
            <div className="row" >
              <div className="small-12 columns">
                <span className="float_left1">Medical condition: </span>
                <span className="float_left1"><TextField label="" name="not_completed_reason_other"
                onChange={(e) => this.handleChange(e)} variant="outlined" className="tf1"
                InputProps={{ maxLength: 100 }}  /></span>
              </div>
              <div className="small-12 columns">Indicate type of mental disorder:</div>
              <div className="small-12 medium-1 columns">&nbsp;</div>
              <div className="small-12 medium-5 columns">Depressive Disorder</div>
              {scid_yesno.map(each =>
                each.value > 0 &&
                <div className="small-12 medium-2 columns align_center" key={'sc'+each.label}>
                  <input type="radio" name="a70_manic" value={each.value}
                  checked={this.state['a70'] == each.value && 'checked'}
                  disabled={(this.state.completed_status && !this.state.edit_status) && 'disabled'}
                  onChange={(e) => this.handleChange(e)}/>
                  <span >{each.label}</span>
                </div>)}
              <div className="small-12 medium-2 columns">&nbsp;</div>
            </div>
            <div className="row" >
              <div className="small-12 medium-1 columns">&nbsp;</div>
              <div className="small-12 medium-5 columns">Bipolar and Related Disorder</div>
              {scid_yesno.map(each =>
                each.value > 0 &&
                <div className="small-12 medium-2 columns align_center" key={'sc'+each.label}>
                  <input type="radio" name="a70_manic" value={each.value}
                  checked={this.state['a70'] == each.value && 'checked'}
                  disabled={(this.state.completed_status && !this.state.edit_status) && 'disabled'}
                  onChange={(e) => this.handleChange(e)}/>
                  <span >{each.label}</span>
                </div>)}
              <div className="small-12 medium-2 columns">&nbsp;</div>
            </div>
            <div className="row" >
              <div className="small-12 medium-1 columns">&nbsp;</div>
              <div className="small-12 medium-5 columns">Anxiety Disorder</div>
              {scid_yesno.map(each =>
                each.value > 0 &&
                <div className="small-12 medium-2 columns align_center" key={'sc'+each.label}>
                  <input type="radio" name="a70_manic" value={each.value}
                  checked={this.state['a70'] == each.value && 'checked'}
                  disabled={(this.state.completed_status && !this.state.edit_status) && 'disabled'}
                  onChange={(e) => this.handleChange(e)}/>
                  <span >{each.label}</span>
                </div>)}
              <div className="small-12 medium-2 columns">&nbsp;</div>
            </div>
            <div className="row" >
              <div className="small-12 medium-1 columns">&nbsp;</div>
              <div className="small-12 medium-5 columns">Obsessive-Compulsive and Related Disorder</div>
              {scid_yesno.map(each =>
                each.value > 0 &&
                <div className="small-12 medium-2 columns align_center" key={'sc'+each.label}>
                  <input type="radio" name="a70_manic" value={each.value}
                  checked={this.state['a70'] == each.value && 'checked'}
                  disabled={(this.state.completed_status && !this.state.edit_status) && 'disabled'}
                  onChange={(e) => this.handleChange(e)}/>
                  <span >{each.label}</span>
                </div>)}
              <div className="small-12 columns"><div className="sep_line2">&nbsp;</div></div>
            </div>
            <div className="row" >
              <div className="small-12 medium-9 columns">
                J2. Probable Substance/Medication-Induced Mental Disorder
              </div>
              {scid_ynmb.map(each =>
                each.value > 0 &&
                <div className="small-12 medium-1 columns align_center" key={'sc'+each.label}>
                  <input type="radio" name="a70" value={each.value}
                  checked={this.state['a70'] == each.value && 'checked'}
                  disabled={(this.state.completed_status && !this.state.edit_status) && 'disabled'}
                  onChange={(e) => this.handleChange(e)}/>
                  <span className="show-for-small-only">{each.label}</span>
                </div>)}
            </div>
            <div className="row" >
              <div className="small-12 columns">
                <span className="float_left1">Medical condition: </span>
                <span className="float_left1"><TextField label="" name="not_completed_reason_other"
                onChange={(e) => this.handleChange(e)} variant="outlined" className="tf1"
                InputProps={{ maxLength: 100 }}  /></span>
              </div>
              <div className="small-12 columns">Indicate type of mental disorder:</div>
              <div className="small-12 medium-1 columns">&nbsp;</div>
              <div className="small-12 medium-5 columns">Depressive Disorder</div>
              {scid_yesno.map(each =>
                each.value > 0 &&
                <div className="small-12 medium-2 columns align_center" key={'sc'+each.label}>
                  <input type="radio" name="a70_manic" value={each.value}
                  checked={this.state['a70'] == each.value && 'checked'}
                  disabled={(this.state.completed_status && !this.state.edit_status) && 'disabled'}
                  onChange={(e) => this.handleChange(e)}/>
                  <span >{each.label}</span>
                </div>)}
              <div className="small-12 medium-2 columns">&nbsp;</div>
            </div>
            <div className="row" >
              <div className="small-12 medium-1 columns">&nbsp;</div>
              <div className="small-12 medium-5 columns">Bipolar and Related Disorder</div>
              {scid_yesno.map(each =>
                each.value > 0 &&
                <div className="small-12 medium-2 columns align_center" key={'sc'+each.label}>
                  <input type="radio" name="a70_manic" value={each.value}
                  checked={this.state['a70'] == each.value && 'checked'}
                  disabled={(this.state.completed_status && !this.state.edit_status) && 'disabled'}
                  onChange={(e) => this.handleChange(e)}/>
                  <span >{each.label}</span>
                </div>)}
              <div className="small-12 medium-2 columns">&nbsp;</div>
            </div>
            <div className="row" >
              <div className="small-12 medium-1 columns">&nbsp;</div>
              <div className="small-12 medium-5 columns">Anxiety Disorder</div>
              {scid_yesno.map(each =>
                each.value > 0 &&
                <div className="small-12 medium-2 columns align_center" key={'sc'+each.label}>
                  <input type="radio" name="a70_manic" value={each.value}
                  checked={this.state['a70'] == each.value && 'checked'}
                  disabled={(this.state.completed_status && !this.state.edit_status) && 'disabled'}
                  onChange={(e) => this.handleChange(e)}/>
                  <span >{each.label}</span>
                </div>)}
              <div className="small-12 medium-2 columns">&nbsp;</div>
            </div>
            <div className="row" >
              <div className="small-12 medium-1 columns">&nbsp;</div>
              <div className="small-12 medium-5 columns">Obsessive-Compulsive and Related Disorder</div>
              {scid_yesno.map(each =>
                each.value > 0 &&
                <div className="small-12 medium-2 columns align_center" key={'sc'+each.label}>
                  <input type="radio" name="a70_manic" value={each.value}
                  checked={this.state['a70'] == each.value && 'checked'}
                  disabled={(this.state.completed_status && !this.state.edit_status) && 'disabled'}
                  onChange={(e) => this.handleChange(e)}/>
                  <span >{each.label}</span>
                </div>)}
              <div className="small-12 columns"><div className="sep_line2">&nbsp;</div></div>
            </div>
          </div>

          <div className="small-12 columns align_right">
            {!this.state.completed_status &&
            <Button variant="contained" color="primary" disableElevation
               onClick={this.handleSubmit} type="submit" className="submit_1">
                Submit
            </Button>}
            {this.state.completed_status && !this.state.edit_status &&
            <Button variant="contained" color="primary" disableElevation
               onClick={(e) => this.handleNext('mini2')} className="submit_1">
                Next
            </Button>}
            {this.state.edit_status &&
            <Button variant="contained" color="primary" disableElevation
               onClick={(e) => this.handleEdit(e)} className="submit_1">
                Submit
            </Button>}
          </div>
        </div>
        </form>
        }

    </div>
    );
  }
}

const mapStateToProps = (state) => {
  return state
}

const mapDispatchToProps = (dispatch) => ({
  onLoadPages(response) {
    dispatch({
      type: 'LOAD_USER_AUTH',
      payload: response,
    })
  },
  onLoadLocation(route, msg) {
    dispatch(loadLocationWithMessage(route, msg))
  },
})

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(Scid5)
